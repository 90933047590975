<template>
  <Page color="info" :title="title">
    <template slot="extension">
      <v-tabs background-color="info darken-1" rounded>
        <v-btn icon :to="{ name: 'People' }" exact
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <v-tab :to="{ name: 'PersonContact' }">Kontakt</v-tab>
        <v-tab
          v-if="isRegisterVisible"
          :to="{
            name: 'Register',
            props: { person: person, view: 'list' },
          }"
          >Unterricht</v-tab
        >
        <v-tab v-if="isCoursesVisible" :to="{ name: 'PersonExams' }"
          >Probenplan</v-tab
        >
        <v-tab v-if="isCoursesVisible" :to="{ name: 'PersonCourses' }"
          >Kurse</v-tab
        >
        <v-tab v-if="isSchoolProfileVisible" :to="{ name: 'PersonProfile' }"
          >Profil</v-tab
        >
      </v-tabs>
    </template>
    <router-view :person="person"></router-view>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Person",
  components: {},
  props: ["id"],
  computed: {
    isCoursesVisible() {
      if (
        !this.person ||
        (this.person.type.code !== "student" &&
          this.person.type.code !== "teacher")
      ) {
        return false;
      }
      if (this.$_hasRole("student")) {
        return this.$_isPerson(this.person);
      } else {
        return true;
      }
    },
    isRegisterVisible() {
      if (
        !this.person ||
        (this.person.type.code !== "student" &&
          this.person.type.code !== "teacher")
      ) {
        return false;
      }
      if (this.$_hasRole("student")) {
        return (
          this.person.type.code === "teacher" || this.$_isPerson(this.person)
        );
      } else {
        return true;
      }
    },
    isSchoolProfileVisible() {
      return (
        this.person &&
        (this.$_isPerson(this.person) ||
          (this.person.type.code === "student" && this.$_hasRole("teacher")))
      );
    },
    title() {
      return this.person
        ? `${this.person.firstName} ${this.person.lastName}`
        : "";
    },
  },
  data() {
    return {
      loading: false,
      person: null,
    };
  },
  watch: {
    id() {
      if (this.id > 0) {
        this.fetchData();
      }
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.person = await this.apiGet({
        resource: "person/person",
        id: this.id,
      });
      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
